























import { Component, Mixins, Watch } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ArticlesTeaserBoxModule } from '../ArticlesTeaserBox.contracts'
import { ArticleCard, ArticleCardProps } from '../../../front/content/organisms/ArticleCard'
import {
  translateContentToArticleCard
} from '../../../front/content/organisms/ArticleCard/ArticleCard.helpers'
import { NavTabsItemProps } from '../../../dsl/molecules/NavTabs'

/**
 * Presentational component for the `ArticlesTeaserBoxModuleUi`.
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ArticlesTeaserBoxModuleUi>({
  name: 'ArticlesTeaserBoxModuleUi',
  components: { ArticleCard },
  mounted () {
    this.currentArticle = this.articles ? this.articles[this.activeItem] : null
  }
})
export class ArticlesTeaserBoxModuleUi extends Mixins<AbstractModuleUi<ArticlesTeaserBoxModule>>(
  AbstractModuleUi) {
  private activeItem: string = '0'
  private currentArticle: ArticleCardProps | null = null

  /**
   * Articles teaser content
   */
  public get articles (): Record<string, ArticleCardProps> {
    if (!this.content.tabs?.length) {
      return {}
    }

    return this.content.tabs.reduce((prev, curr, index) => ({
      ...prev,
      [this.tabs[index].id]: curr.article ? translateContentToArticleCard(curr.article) : null
    }), {})
  }

  public get tabs (): NavTabsItemProps[] {
    if (!this.content.tabs?.length) {
      return []
    }

    return this.content.tabs.map((item, i) => ({
      active: i === 0,
      id: i.toString(),
      label: item.name
    }))
  }

  /**
   * Determines whether the component has been provided with the correct `content` prop.
   */
  public get hasContent (): boolean {
    return !!this.content.tabs && this.content.tabs.length > 0
  }

  @Watch('activeItem')
  public changeTab (index: string): void {
    this.currentArticle = this.articles[index]
  }
}

export default ArticlesTeaserBoxModuleUi
